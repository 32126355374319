import ChildrenAgesSelector from './children-ages-selector';

export default class RoomOccupancy {
    // eslint-disable-next-line max-params
    constructor(main, breakdown, row, roomIndex, initial = undefined) {
        this._hotelData = main.getHotelData();
        row = this._prepareRow(main, row, roomIndex);
        this._adultsSelector = row.querySelector(breakdown.getClass('adultsSelector'));
        this._childrenSelector = row.querySelector(breakdown.getClass('childrenSelector'));
        this._agesInput = row.querySelector(breakdown.getClass('agesInput'));
        this._childrenAgesSelector = new ChildrenAgesSelector(
            row, this._agesInput, this._hotelData
        );
        this._setSelection(initial);
        this._populateAdultsSelector();
        this._populateChildrenSelector();
        this._attachListeners(breakdown, row);
        return row;
    }

    _prepareRow(main, row, roomIndex) {  // eslint-disable-line
        return row;
    }

    _setSelection(initial) {
        this._selection = {
            adults: initial === undefined ? 2 : initial.adults,
            children: initial === undefined ? 0 : initial.children,
            ages: initial === undefined ? '' : initial.ages
        };
    }

    _populateAdultsSelector() {
        RoomOccupancy._prepareSelect(this._adultsSelector, !this._hotelData);
        if (!this._hotelData) {
            this._adultsSelector.disabled = true;
            return;
        }
        const allowedOccupancies = Object.keys(this._hotelData.allowedOccupancies).map(key => +key),
            maxAdults = Math.max(...allowedOccupancies);

        if (this._selection.adults > maxAdults) {
            this._selection.adults = maxAdults;
        }
        RoomOccupancy._fillSelect(
            this._adultsSelector, allowedOccupancies, this._selection.adults
        );
    }

    _populateChildrenSelector() {
        if (this._childrenSelector) {
            RoomOccupancy._prepareSelect(this._childrenSelector, true);
            const maxChildren = (
                this._selection.adults && this._hotelData !== undefined
                    ? this._hotelData.allowedOccupancies[this._selection.adults]
                    : 0
            );

            if (maxChildren === 0) {
                this._childrenSelector.disabled = true;
            } else {
                const lenghChildren = Array.from(Array(maxChildren).keys(), x => x + 1);
                RoomOccupancy._fillSelect(
                    this._childrenSelector, lenghChildren, this._selection.children
                );
            }
            this._childrenAgesSelector.create(+this._childrenSelector.value, this._selection.ages);
        }
    }

    static _prepareSelect(element, withEmptyOption) {
        element.removeAttribute('disabled');
        if (withEmptyOption) {
            let text;
            try {
                text = element.dataset.textPl;
            } catch (e) {
                text = element.getAttribute('data-text-pl');
            }
            text = text[0].toUpperCase() + text.slice(1);
            element.innerHTML = `<option value="0">0 ${text}</option>`;
        } else {
            element.innerHTML = '';
        }
    }

    static _fillSelect(element, occupancies, selectedValue) {
        let selected,
            textSing,
            textPl;
        try {
            textSing = ` ${element.dataset.textSing}`;
            textPl = ` ${element.dataset.textPl}`;
        } catch (e) {
            textSing = ` ${element.getAttribute('data-text-sing')}`;
            textPl = ` ${element.getAttribute('data-text-pl')}`;
        }

        for (let i = 0; i < occupancies.length; i++) {
            selected = occupancies[i] === selectedValue ? 'selected="selected"' : '';
            element.innerHTML += (
                `<option value="${occupancies[i]}" ${selected}>
                    ${occupancies[i]}${occupancies[i] === 1 ? textSing : textPl}
                </option>`
            );
        }
    }

    _attachListeners(breakdown, row) { // eslint-disable-line no-unused-vars
        this._adultsSelector.addEventListener('change', (event) => {
            this._selection.adults = +event.currentTarget.value || 0;
            this._populateChildrenSelector();
        });
        if (this._childrenSelector) {
            this._childrenSelector.addEventListener('change', (event) => {
                this._selection.children = +event.currentTarget.value || 0;
                this._childrenAgesSelector.create(this._selection.children);
            });
        }
    }
}
