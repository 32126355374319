import RoomOccupancy from './room-occupancy';

export default class MultipleRoomOccupancy extends RoomOccupancy {
    _attachListeners(breakdown, row) {
        super._attachListeners(breakdown, row);
        const removeButton = row.querySelector('.js-roi-search-engine-remove-room');
        if (removeButton === null) {
            return;
        }
        removeButton.addEventListener('click', (event) => {
            event.stopPropagation();
            row.parentNode.removeChild(row);
            breakdown.removeRoom();
        });
    }

    _prepareRow(main, row, roomIndex) { // eslint-disable-line class-methods-use-this
        row.innerHTML = row.innerHTML.replace(
            /{ROOM-COUNTER-PLACEHOLDER}/g, roomIndex
        );
        return row;
    }
}
