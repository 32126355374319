import RoomOccupancy from './room-occupancy';

/* global RoiCalendar */
export default class OccupancyBreakdown {
    constructor(main) {
        this._main = main;
        this._classes = {
            adultsSelector: '.js-roi-search-engine-adults',
            agesInput: '.js-roi-search-engine-ages',
            childrenSelector: '.js-roi-search-engine-children',
            errorTemplate: '.js-roi-search-engine-occupancies-error',
            roomRow: '.js-roi-search-engine-occupancy-room'
        };
        this._container = this._main.getEngineForm().querySelector(
            '.js-roi-search-engine-occupancy-breakdown-container'
        );
        this._breakdown = this._container.querySelector(
            '.js-roi-search-engine-room-breakdown'
        );
        this._overlay = RoiCalendar.getOverlay(this._saveOccupancies.bind(this));
        this._occupanciesInput = this._main.getEngineForm().querySelector(
            '.js-roi-search-engine-occupancies-values'
        );
        this._saveButton = this._container.querySelector(
            '.js-roi-search-engine-save-occupancy'
        );
        this._errorTemplate = this._getTemplate(true);
        this._template = this._getTemplate(false);
        this._keepFocusInBreakdown();
        this._listenToOccupanciesSaving();
    }

    getClass(key) { return this._classes[key]; }

    launch(initial) {
        if (initial) {
            this._setInitialOccupancies(initial);
        } else {
            this._resetSelection();
        }
    }

    open() {
        this._container.remove();
        this._container.style.removeProperty('display');
        document.body.appendChild(this._container);
        this._overlay.show();
        this._focusFirstVisibleElement();
    }

    _clearErrorMessages() {
        this._breakdown.querySelectorAll(this._classes.errorTemplate).forEach((error) => {
            error.remove();
        });
    }

    _focusFirstVisibleElement() {
        const adultSelectors = this._breakdown.querySelectorAll(this._classes.adultsSelector);
        for (let i = 0; i < adultSelectors.length; i++) {
            const current = adultSelectors[i];
            if (current.offsetParent !== null) { // If 'current' is visible
                current.focus();
                break;
            }
        }
    }

    _focusSaveButtonOnBackwardsTab(element) {
        element.addEventListener('keydown', (event) => {
            if (event.key === 'Tab' && event.shiftKey) {
                this._saveButton.focus();
            }
        });
    }

    _getRoomOccupancy(room) {
        const adults = room.querySelector(this._classes.adultsSelector).value,
            children = room.querySelector(this._classes.childrenSelector),
            numberChildren = children ? children.value : 0;

        return {
            adults: adults > 0 ? adults : 2,
            children: numberChildren,
            ages: numberChildren > 0 ? room.querySelector(this._classes.agesInput).value : ''
        };
    }

    _getSelectedOccupancies() {
        return [[this._getRoomOccupancy(this._breakdown)], 1];
    }

    _getTemplate(forError) {
        const template = forError
            ? this._container.querySelector(this._classes.errorTemplate)
            : this._breakdown.querySelector(this._classes.roomRow);
        return template.parentNode.removeChild(template);
    }

    _keepFocusInBreakdown() {
        this._saveButton.addEventListener('keydown', (e) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                this._focusFirstVisibleElement();
            }
        });
    }

    _listenToOccupanciesSaving() {
        this._saveButton.addEventListener('click', () => {
            this._saveOccupancies();
        });
    }

    _resetSelection(initialValue = undefined) {
        this._breakdown.innerHTML = '';
        this._breakdown.appendChild(new RoomOccupancy(
            this._main,
            this,
            this._template.cloneNode(true),
            1,
            initialValue
        ));
        this._focusSaveButtonOnBackwardsTab(
            this._breakdown.querySelector(this._classes.adultsSelector)
        );
        this._updateSelectedOccupancies();
    }

    _saveOccupancies() {
        let someAgeIsMissing = false;
        this._clearErrorMessages();
        this._breakdown.querySelectorAll('.js-roi-search-engine-ages-container').forEach((row) => {
            let someAgeIsMissingInRow = false;
            row.querySelectorAll('select').forEach((select) => {
                if (!someAgeIsMissingInRow && select.value === '') {
                    someAgeIsMissing = true;
                    someAgeIsMissingInRow = true;
                    row.appendChild(this._errorTemplate.cloneNode(true));
                }
            });
        });
        if (!someAgeIsMissing) {
            this._overlay.hide();
            this._updateSelectedOccupancies();
        }
    }

    _setInitialOccupancies(initial = undefined) {
        let initialValue;
        try {
            initialValue = JSON.parse(this._occupanciesInput.value);
        } catch (e) {
            initialValue = undefined;
        }
        if (initialValue !== undefined) {
            if (initialValue instanceof Array) {
                [initialValue] = initialValue;
            } else {
                initialValue = undefined;
            }
        }

        if (typeof (initial) === 'object') {
            initialValue = initial;
        }

        this._resetSelection(initialValue);
    }

    _updateSelectedOccupancies() {
        const [selectedOccupancies, roomCount] = this._getSelectedOccupancies();
        this._occupanciesInput.value = JSON.stringify(selectedOccupancies);
        this._main.updateSummary(selectedOccupancies, roomCount);
        this._container.style.display = 'none';
    }
}
