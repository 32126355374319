export default class ChildrenAgesSelector {
    constructor(row, agesInput, hotelData) {
        this._hotelData = hotelData;
        this._agesRow = row.querySelector('.js-roi-search-engine-ages-container');
        if (this._agesRow) {
            this._agesInput = this._agesRow.removeChild(agesInput);
            this._labelTemplate = this._agesRow.removeChild(this._agesRow.querySelector('label'));
            this._selectTemplate = this._agesRow.removeChild(this._agesRow.querySelector('select'));
        }
    }

    create(numberOfChildren, ages = undefined) {
        this._agesInput.value = ages || '';
        this._agesRow.innerHTML = '';
        if (numberOfChildren === 0) {
            this._agesRow.style.display = 'none';
            return;
        }
        this._agesRow.appendChild(this._agesInput);
        const selectors = [];
        if (ages === undefined) {
            // Create a new array like [-1, -1, ..., -1]
            ages = Array(numberOfChildren - 1).fill(-1);
        } else {
            ages = ages.split(';').map(x => +x);
        }
        for (let i = 1; i <= numberOfChildren; i++) {
            selectors.push(this._populateAgesRow(i, ages[i - 1]));
        }
        this._updateSelectedAges(selectors);
        this._agesRow.style.removeProperty('display');
    }

    _populateAgesRow(roomIndex, selectedAge) {
        const placeholder = '{CHILDREN-COUNTER-PLACEHOLDER}',
            currentSelector = this._getAgesSelector(placeholder, roomIndex, selectedAge);
        this._agesRow.appendChild(this._getLabel(placeholder, roomIndex));
        this._agesRow.appendChild(currentSelector);
        return currentSelector;
    }

    _getAgesSelector(placeholder, roomIndex, selectedAge) {
        const select = this._selectTemplate.cloneNode(false);
        select.innerHTML += '<option value="">&mdash;</option>';
        let selected;
        for (let i = 0; i <= this._hotelData.childrenMaxAge; i++) {
            selected = i === +selectedAge ? 'selected' : '';
            select.innerHTML += `<option value="${i}" ${selected}>${i}</option>`;
        }
        select.id = select.id.replace(placeholder, roomIndex);
        select.setAttribute(
            'aria-label',
            select.getAttribute('aria-label')
                .replace(placeholder, roomIndex)
                .replace(/\n\s+/g, ' ')
        );
        return select;
    }

    _getLabel(placeholder, roomIndex) {
        const label = this._labelTemplate.cloneNode(true);
        if (roomIndex > 1) {
            label.classList.add('roi-search-engine__occupancy-label--hidden');
        }
        label.setAttribute(
            'for', label.getAttribute('for').replace(placeholder, roomIndex)
        );
        return label;
    }

    _updateSelectedAges(selectors) {
        selectors.forEach((selector) => {
            selector.addEventListener('change', () => {
                this._agesInput.value = selectors.map(elem => +elem.value).join(';');
            });
        });
        selectors[0].dispatchEvent(new CustomEvent('change'));
    }
}
