import OccupancyBreakdown from './occupancy-breakdown';

export default class OccupancyBreakdownV2 extends OccupancyBreakdown {
    constructor(main) {
        super(main);
        this._body = document.querySelector('body');
        this._summary = main.getEngineForm().querySelector(
            '.js-roi-search-engine-occupancy-summary'
        );
        this._occupancySelector = main.getEngineForm().querySelector(
            '.roi-search-engine__item--occupancy'
        );
        this._listenToClickOutsideBreakdown();
        this._positionDropdown();
    }

    open() {
        this._container.remove();
        this._container.style.removeProperty('display');
        this._occupancySelector.appendChild(this._container);
        this._focusFirstVisibleElement();
    }

    _listenToClickOutsideBreakdown() {
        this._body.addEventListener('click', (event) => {
            const { target } = event;
            if (
                this._isBreakdownVisible() &&
                !this._container.contains(target) &&
                !this._summary.contains(target)
            ) {
                this._saveOccupancies();
                this._hideOccupanciesBreakdown();
            }
        });
    }

    _isBreakdownVisible() {
        return window.getComputedStyle(this._container).display !== 'none';
    }

    _hideOccupanciesBreakdown() {
        this._container.style.display = 'none';
    }

    _positionDropdown() {
        this._summary.addEventListener('click', (event) => {
            const { target } = event,
                _engineSearch = document.querySelector('.js-roi-search-engine'),
                _navigatorFixed = document.querySelector('.navigator--fixed');
            let _selectedEngine = _engineSearch;

            if (_navigatorFixed && _navigatorFixed.contains(target)) {
                _selectedEngine = _navigatorFixed;
            }

            const occupancyDropdownTop =
                _selectedEngine.getBoundingClientRect().bottom -
                this._occupancySelector.getBoundingClientRect().bottom;

            if (occupancyDropdownTop < 40 && occupancyDropdownTop > 0) {
                this._container.style.top = `calc(100% + ${occupancyDropdownTop}px)`;
            } else {
                this._container.style.top = '100%';
            }
        });
    }

    _saveOccupancies() {
        let someAgeIsMissing = false;
        this._clearErrorMessages();
        this._breakdown.querySelectorAll('.js-roi-search-engine-ages-container').forEach((row) => {
            let someAgeIsMissingInRow = false;
            row.querySelectorAll('select').forEach((select) => {
                if (!someAgeIsMissingInRow && select.value === '') {
                    someAgeIsMissing = true;
                    someAgeIsMissingInRow = true;
                    row.appendChild(this._errorTemplate.cloneNode(true));
                }
            });
        });
        if (!someAgeIsMissing) {
            this._updateSelectedOccupancies();
        }
    }
}
