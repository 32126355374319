export default class OccupancySummary {
    constructor(main) {
        this._summary = main.getEngineForm().querySelector(
            '.js-roi-search-engine-occupancy-summary'
        );
    }

    getSummary() { return this._summary; }

    updateSummary(occupancies, roomCounter) {
        const roomCount = this._summary.querySelector(
            '.js-roi-search-engine-occupancy-room-count'
        );
        if (roomCount !== null) {
            let roomText;
            try {
                roomText = roomCount.dataset[`text${roomCounter === 1 ? 'Sing' : 'Pl'}`];
            } catch (e) {
                roomText = roomCount.getAttribute(`data-text-${roomCounter === 1 ? 'sing' : 'pl'}`);
            }
            roomCount.innerText = `${roomCounter} ${roomText}`;
        }
        this._updateSummaryField(occupancies, true);
        this._updateSummaryField(occupancies, false);
    }

    _updateSummaryField(occupancies, adults) {
        const items = adults ? 'adults' : 'children',
            selectedItems = occupancies.reduce(
                (acc, curr) => acc + (+curr[items]),
                0
            ),
            selectedItemsText = this._summary.querySelector(
                `.js-roi-search-engine-selected-${items}-text`
            );

        if (selectedItemsText) {
            this._summary.querySelector(
                `.js-roi-search-engine-selected-${items}`
            ).innerText = selectedItems;
            try {
                selectedItemsText.innerText = selectedItemsText.dataset[
                    `text${selectedItems === 1 ? 'Sing' : 'Pl'}`
                ];
            } catch (e) {
                selectedItemsText.innerText = selectedItemsText.getAttribute(
                    `data-text-${selectedItems === 1 ? 'sing' : 'pl'}`
                );
            }
        }
    }
}
