import MultipleRoomOccupancy from './multiple-room-occupancy';
import OccupancyBreakdown from './occupancy-breakdown';

export default class MultipleOccupancyBreakdown extends OccupancyBreakdown {
    constructor(main) {
        super(main);
        this._addRoomButton = this._container.querySelector('.js-roi-search-engine-add-room');
        this._classes = {
            ...this._classes,
            lastRoom: 'roi-search-engine__occupancy-room-item--last',
            title: 'js-roi-search-engine-occupancies-title'
        };
        this._roomCounter = 0;

        this._listenToRoomAdding();
    }

    removeRoom() {
        if (this._maxRoomsReached()) {
            this._addRoomButton.style.visibility = 'visible';
        }
        this._roomCounter -= 1;
        const rooms = this._getAllRooms();
        // Attempted to do it with :last-of-type, didn't work for all cases ¯\_(ツ)_/¯
        rooms[rooms.length - 1].classList.add(this._classes.lastRoom);
    }

    _addRoom(initial = undefined) {
        const newRoom = this._createRoomRow(initial),
            previousLastRoom = this._breakdown.querySelector(`.${this._classes.lastRoom}`);

        if (previousLastRoom === null) {
            newRoom.classList.add('roi-search-engine__occupancy-room-item--first');
            this._focusSaveButtonOnBackwardsTab(
                newRoom.querySelector(this._classes.adultsSelector)
            );
        } else {
            previousLastRoom.classList.remove(this._classes.lastRoom);
        }
        newRoom.classList.add(this._classes.lastRoom);
        this._breakdown.appendChild(newRoom);
        if (this._maxRoomsReached()) {
            this._addRoomButton.style.visibility = 'hidden';
        }
    }

    _createRoomRow(initial = undefined) {
        return new MultipleRoomOccupancy(
            this._main,
            this,
            this._template.cloneNode(true),
            ++this._roomCounter,
            initial
        );
    }

    _getAllRooms() {
        return this._breakdown.querySelectorAll(this._classes.roomRow);
    }

    _listenToRoomAdding() {
        this._addRoomButton.addEventListener('click', () => this._addRoom());
    }

    _maxRoomsReached() {
        return this._roomCounter === this._main.getMaxRooms();
    }

    _resetSelection(initialValue = undefined) { // eslint-disable-line no-unused-vars
        this._roomCounter = 0;
        const title = this._breakdown.removeChild(
            this._breakdown.querySelector(`.${this._classes.title}`)
        );
        this._breakdown.innerHTML = '';
        this._breakdown.appendChild(title);
        this._addRoom(initialValue);
        this._updateSelectedOccupancies();
        this._addRoomButton.style.visibility = 'visible';
    }

    _setInitialOccupancies(initial = undefined) {
        if (this._occupanciesInput.value === '') {
            this._addRoom(initial);
        } else {
            let initialValue;
            if (typeof (initial) === 'object') {
                initialValue = initial;
            } else {
                try {
                    initialValue = JSON.parse(this._occupanciesInput.value);
                } catch (e) {
                    initialValue = undefined;
                }
                if (initialValue !== undefined) {
                    if (initialValue instanceof Array) {
                        [initialValue] = initialValue;
                    } else {
                        initialValue = undefined;
                    }
                }
            }
            this._resetSelection(initialValue);
        }
        this._updateSelectedOccupancies();
    }

    _getSelectedOccupancies() {
        const selectedOccupancies = [];
        this._getAllRooms().forEach((room) => {
            selectedOccupancies.push(this._getRoomOccupancy(room));
        });
        return [selectedOccupancies, this._roomCounter];
    }
}
