import MultipleOccupancyBreakdown from './multiple-occupancy-breakdown';

export default class MultipleOccupancyBreakdownV2 extends MultipleOccupancyBreakdown {
    constructor(main) {
        super(main);
        this._isDropdownPositioned = false;
        this._body = document.querySelector('body');
        this._summary = main.getEngineForm().querySelector(
            '.js-roi-search-engine-occupancy-summary'
        );
        this._occupancySelector = main.getEngineForm().querySelector(
            '.roi-search-engine__item--occupancy'
        );
        this._listenToClickOutsideBreakdown();
        this._positionDropdown();
    }

    open() {
        this._container.remove();
        this._container.style.removeProperty('display');
        this._occupancySelector.appendChild(this._container);
        this._focusFirstVisibleElement();
    }

    _listenToClickOutsideBreakdown() {
        this._body.addEventListener('click', (event) => {
            const { target } = event;
            if (
                this._isBreakdownVisible() &&
                !this._container.contains(target) &&
                !this._summary.contains(target)
            ) {
                this._saveOccupancies();
                this._hideOccupanciesBreakdown();
            }
        });
    }

    _isBreakdownVisible() {
        return window.getComputedStyle(this._container).display !== 'none';
    }

    _hideOccupanciesBreakdown() {
        this._container.style.display = 'none';
    }

    _positionDropdown() {
        this._summary.addEventListener('click', (event) => {
            const { target } = event,
                _engineSearch = document.querySelector('.js-roi-search-engine'),
                _navigatorFixed = document.querySelector('.navigator--fixed');
            let _selectedEngine = _engineSearch;

            if (_navigatorFixed && _navigatorFixed.contains(target)) {
                _selectedEngine = _navigatorFixed;
            }

            const occupancyDropdownTop =
                _selectedEngine.getBoundingClientRect().bottom -
                this._occupancySelector.getBoundingClientRect().bottom;

            if (occupancyDropdownTop < 40 && occupancyDropdownTop > 0) {
                this._container.style.top = `calc(100% + ${occupancyDropdownTop}px)`;
            } else {
                this._container.style.top = '100%';
            }
        });
    }

    removeRoom() {
        if (this._maxRoomsReached()) {
            this._addRoomButton.style.display = 'block';
        }
        this._roomCounter -= 1;
        const rooms = this._getAllRooms();
        // Attempted to do it with :last-of-type, didn't work for all cases ¯\_(ツ)_/¯
        rooms[rooms.length - 1].classList.add(this._classes.lastRoom);
    }

    _addRoom(initial = undefined) {
        const newRoom = this._createRoomRow(initial),
            previousLastRoom = this._breakdown.querySelector(`.${this._classes.lastRoom}`);

        if (previousLastRoom === null) {
            newRoom.classList.add('roi-search-engine__occupancy-room-item--first');
            this._focusSaveButtonOnBackwardsTab(
                newRoom.querySelector(this._classes.adultsSelector)
            );
        } else {
            previousLastRoom.classList.remove(this._classes.lastRoom);
        }
        newRoom.classList.add(this._classes.lastRoom);
        this._breakdown.appendChild(newRoom);
        if (this._maxRoomsReached()) {
            this._addRoomButton.style.display = 'none';
        }
    }

    _resetSelection(initialValue = undefined) { // eslint-disable-line no-unused-vars
        this._roomCounter = 0;
        const title = this._breakdown.removeChild(
            this._breakdown.querySelector(`.${this._classes.title}`)
        );
        this._breakdown.innerHTML = '';
        this._breakdown.appendChild(title);
        this._addRoom(initialValue);
        this._updateSelectedOccupancies();
        this._addRoomButton.style.display = 'block';
    }

    _saveOccupancies() {
        let someAgeIsMissing = false;
        this._clearErrorMessages();
        this._breakdown.querySelectorAll('.js-roi-search-engine-ages-container').forEach((row) => {
            let someAgeIsMissingInRow = false;
            row.querySelectorAll('select').forEach((select) => {
                if (!someAgeIsMissingInRow && select.value === '') {
                    someAgeIsMissing = true;
                    someAgeIsMissingInRow = true;
                    row.appendChild(this._errorTemplate.cloneNode(true));
                }
            });
        });
        if (!someAgeIsMissing) {
            this._updateSelectedOccupancies();
        }
    }
}
